import plato from '../Galerie/fotky/plato2.jpg'
import grilovane from '../Galerie/fotky/grilovane.jpg'
import drt from '../Galerie/fotky/drt.jpg'
import drt2 from '../Galerie/fotky/drt2.jpg'
import vejce from '../Galerie/fotky/krepelciVejce.jpg'

export const odstavce = [
   {
      id: 1,
      side: 'left',
      nadpis: 'nadpisVlevo',
      name: 'O NÁS.',
      classP: 'paragraphLeft',
      paragraph:
         'Naše farma se nachází na samotě v těsné blízkosti chráněného území Orlických hor. Zaměřujeme se především na chov drůbeže a prodej čerstvých vajec, masa i živých zvířat ( kuřata, křepelky, husy ). Kvalitní strava, pohoda a klid zvířat spolu s dostatečně velkým výběhem a pastvou je základem zdravého chovu.',
      classImg: 'img',
      img: plato,
      alt: 'plato',
      data_aos: 'fade-right',
   },
   {
      id: 2,
      side: 'right',
      nadpis: 'nadpisVpravo',

      name: 'PROČ JÍST KŘEPELČÍ VAJÍČKA ?',
      classP: 'paragraphRight',
      paragraph:
         'Pravidelná konzumace křepelčích vajec zvyšuje odolnost imunitního systému a snižuje pravděpodobnost virové nákazy, rýmy, infarktů a průduškových katarů. Úspěšně se pro své dietetické vlastnosti používají při některých nervových onemocněních, léčbě cukrovky, astmatu, nemocech žaludku a tlustého střeva. Pro svůj vysoký obsah vitamínů, minerálních látek a biologické složení jsou vhodná proti celkové vyčerpanosti a pro zlepšení mužské potence.',
      classImg: 'img2',
      img: vejce,
      alt: 'plato',
      data_aos: 'fade-left',
   },

   {
      id: 3,
      side: 'left',
      nadpis: 'nadpisVlevo',
      name: 'PROČ KŘEPELČÍ MASO?',
      classP: 'paragraphLeft',
      paragraph:
         'Křepelčí maso je považováno za delikatesu, podobně jako křepelčí vejce. Je velmi málo kalorické, podobně jako kuřecí oproti němu je však o mnoho výživnější, i zdravější. Obsahuje velké množství vitamínů, železa a selenu a díky nízkému obsahu tuku nezvyšuje cholesterol v krvi.',
      classImg: 'img',
      img: grilovane,
      alt: 'plato',
      data_aos: 'fade-right',
   },
   {
      id: 3,
      side: 'right',
      nadpis: 'nadpisVpravo',

      name: 'Skořápka z křepelčích vajec',
      classP: 'paragraphRight',
      paragraph:
         'Skořápka křepelčích vajec – minerální doplněk stravy, je zdrojem lehce vstřebatelného vápníku a 26 dalších prvků, včetně mědi, fluoridů, železa, manganu, molybdenu, fosforu, síry, zinku, křemíku, jódu a kobaltu. Zvláště cenné jsou křemík a molybden. Naše každodenní jídlo je velmi chudé na tyto prvky, jsou nezbytné pro normální biochemické reakce v těle. Jemně rozdrcené je můžeme přidat do všech vařených jídel nebo také do medu anebo jen tak na špičku lžičky do úst. Taková léčba je nejen velice efektivní, ale nemá ani žádné vedlejší účinky a nehrozí riziko předávkování. Suché vaječné skořápky obsahují dále uhličitany: uhličitan vápenatý a hořečnatý; oxidy: oxid křemičitý, hlinitý a fosforečný; dusíkaté látky a bílkoviny. Jemně mleté skořápky mají výborné neutralizační schopnosti – otupují přebytečnou žaludeční kyselinu. Otupení je trvalé a nemá škodlivé následky, jako např. v případě požití sody na špičku nože několikrát denně.',
      classImg: 'img2',
      img: drt,
      alt: 'plato',
      data_aos: 'fade-left',
   },
]
