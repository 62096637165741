import React from 'react'
import data from './data'
import style from './info.module.css'
import { Store } from '../chart/CartReducer'
import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import * as BsIcons from 'react-icons/bs'
import * as giIcons from 'react-icons/gi'
import * as AiIcons from 'react-icons/ai'
import * as AiOutlineArrowLeft from 'react-icons/ai'
import * as GrIcons from 'react-icons/gr'
import * as TirIcons from 'react-icons/ti'
function Info(props) {
   const infoProduct = data.find((value) => value.id === props.id) || {}
   const { cart, setCart, infoAdded } = useContext(Store)
   const [qty, setQty] = useState(1)
   const addToCart = (products) => {
      let newCart = [...cart]
      let itemInCart = newCart.find((item) => item.name === products.name)

      if (itemInCart) {
         itemInCart.quantity += Number(qty)
      } else if (Number(qty) === 0) {
         return
      } else {
         itemInCart = {
            ...products,
            quantity: Number(qty),
         }
         newCart.push(itemInCart)
      }
      setCart(newCart)
   }

   const kvantita = (e) => {
      if (e.target.value <= 1) {
         return
      } else {
         setQty(e.target.value)
      }
   }
   const popup = () => {
      addToCart(infoProduct)
      infoAdded()
      setQty(1)
   }
   const increment = () => {
      if (Number(qty) >= 99) {
         return
      } else {
         setQty(Number(qty) + 1)
      }
   }

   const decrement = () => {
      if (qty <= 1) {
         return
      } else {
         setQty(qty - 1)
      }
   }

   const oninnput = (o) => {
      if (o.target.value.length > o.target.maxLength) {
         o.target.value = o.target.value.slice(0, o.target.maxLength)
      }
   }
   return (
      <div>
         <div className={style.card}>
            <img
               className={style.img}
               src={infoProduct.img}
               alt={infoProduct.name}
            />
            <div className={style.rightSide}>
               <h3 className={style.name}>{infoProduct.name}</h3>
               <h4 className={style.price}>
                  {infoProduct.price} Kč
                  <p className={style.dph}>cena včetně DPH</p>
               </h4>
               <div className={style.buy}>
                  <div className={style.buttony}>
                     <button
                        key={infoProduct.id}
                        className={style.minus}
                        onClick={decrement}
                     >
                        <AiIcons.AiOutlineMinus />
                     </button>

                     <input
                        className={style.quantity}
                        type="number"
                        id="quantity"
                        onChange={kvantita}
                        min="1"
                        value={Number(qty)}
                        onInput={oninnput}
                        maxLength="2"
                     />

                     <button
                        key={infoProduct.id}
                        className={style.plus}
                        onClick={increment}
                     >
                        <GrIcons.GrAdd />
                     </button>
                  </div>
                  <div onClick={popup}>
                     <button className={style.btn}>Vložit do košíku</button>
                  </div>
               </div>{' '}
               <Link to="/produkty">
                  <button className={style.arrow}>
                     <BsIcons.BsBoxArrowInLeft />
                  </button>
               </Link>
               <p className={style.information}>{infoProduct.info}</p>
            </div>
         </div>
      </div>
   )
}

export default Info
