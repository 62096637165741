import React from 'react'
import data from './data'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Info from './Info'
function ProductCard() {
   return (
      <div>
         {data.map((item, index) => (
            <div key={item.id}>
               <Route path={`/produkty/${item.id}`}>
                  <Info key={index} item={item} id={item.id} />
               </Route>
            </div>
         ))}
      </div>
   )
}

export default ProductCard
