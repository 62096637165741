import React, { useState, useContext, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'

import data from './data'
import Info from './Info'
import { Link } from 'react-router-dom'
import { Store } from '../chart/CartReducer'
import Aos from 'aos'
import 'aos/dist/aos.css'

import styles from './Products.module.css'
import * as giIcons from 'react-icons/gi'

function Products(props) {
   const { cart, setCart, addToCart, infoAdded } = useContext(Store)
   const [click, setClick] = useState(false)

   const clicked = () => setClick(!click)
   const popup = () => {
      infoAdded()
   }
   useEffect(() => {
      Aos.init({ duration: 2000 })
   }, [])
   return (
      <div>
         <h1 className={styles.nadpis}>
            Naše produkty OBJEDNÁVKY NA
            <a href="tel:+775112341">
               <br />
               775 112 341
            </a>
            <li class={styles.mailto}>
               <a href="mailto:prodej@rodinnafarmicka.cz">
                  prodej@rodinnafarmicka.cz
               </a>
            </li>
         </h1>
         <div className={styles.outer_wrapper}>
            {data.map((item) => (
               <div className={styles.vse}>
                  <div className={styles.produkty} key={item.id}>
                     <div className={styles.container}>
                        <img
                           className={styles.images}
                           src={item.img}
                           alt={item.name}
                        />
                     </div>
                     <div class={styles.bottom}>
                        <div class={styles.left}>
                           <div class={styles.details}>
                              <h2 className={styles.jmeno}>{item.name}</h2>
                              <h3 className={styles.pocet}>{item.pocet}</h3>
                              <h3 className={styles.pocet}>{item.pocet2}</h3>
                              <h3 className={styles.pocet}>{item.pocet3}</h3>
                              <h3 className={styles.cena}>{item.price}</h3>
                           </div>
                           {/*     <div className={styles.buy} onClick={popup}>
                              <div
                                 className={styles.buy}
                                 onClick={() => addToCart(item)}
                              >
                                 <i className={styles.icons}>
                                    <giIcons.GiShoppingCart />
                                 </i>
                              </div>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default Products
