import vajickoFoto from '../Galerie/fotky/vajickoProdukt.jpg'
import kuře from '../Galerie/fotky/Quail-510x600.jpg'
import nasadovaVejce from '../Galerie/fotky/nasadovaVejce.jpg'
import plato2 from '../Galerie/fotky/platovajec.jpg'
import nakladacky from '../Galerie/fotky/nakladacky150.jpg'
import nakladacky2 from '../Galerie/fotky/nakladacky60.jpg'
import ruznyvek from '../Galerie/fotky/líhnutí.jpg'
import krepelka from '../Galerie/fotky/krepelka.jpg'
import med from '../Galerie/fotky/med.jpg'
import kuchynuprava from '../Galerie/fotky/kuchynuprava3.jpg'

const data = [
   {
      name: 'Konzumní křepelčí vajíčka',
      pocet3: '< 50ks........3,20',
      pocet2: '< 100ks........3,-kč',
      pocet: 18 + 'ks........' + '3,50,-kč',
      id: 0,
      img: plato2,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   },

   {
      name: 'Násadová vejce křepelky japonské masné',
      price: 6 + ',-kč',
      pocet: 1 + 'ks',

      id: 1,
      img: nasadovaVejce,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   },
   {
      name: 'Křepelčí vajíčka v pikatním nálevu',
      price: 180 + ',-kč',
      pocet: 30 + 'ks vajec',

      id: 2,
      img: nakladacky2,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   },
   /*    {
      name: 'Křepelčí vajíčka v pikatním nálevu',
      price: 150,
      pocet: 30 + 'ks',

      id: 3,
      img: nakladacky,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   }, */
   {
      name: 'slepičky a kohoutci, různého věku',
      price: 'od ' + 35 + ' ,-kč do ' + 125 + ',-kč',
      pocet: 1 + 'ks',

      id: 4,
      img: ruznyvek,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   },
   {
      name: 'křepelky v kuchyňské úpravě 1KG',
      price: 385 + ',-kč',
      pocet: 4 + '-' + 6 + 'ks',

      id: 5,
      img: kuchynuprava,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   },
   /*   {
      name: 'včelí med z Orlických hor',
      price: 200,
      pocet: 1 + 'KG',

      id: 6,
      img: med,
      info: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam faucibus mi quis velit. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Quisque tincidunt scelerisque libero. Nullam rhoncus aliquam metus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam posuere lacus quis dolor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus faucibus molestie nisl. Nullam rhoncus aliquam metus. Donec vitae arcu.',
   }, */
]

export default data
