import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { CartProvider } from './components/chart/CartReducer'
import { AuthProvider } from './components/Login/RegisterState'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ToastProvider, useToasts } from 'react-toast-notifications'

const stripe = loadStripe(process.env.REACT_APP_KEY_PUBLIC, {
   locale: 'cs',
})

ReactDOM.render(
   <Elements stripe={stripe}>
      <ToastProvider
         autoDismiss
         autoDismissTimeout={1000}
         placement="top-center"
      >
         <AuthProvider>
            <CartProvider>
               <App />
            </CartProvider>
         </AuthProvider>
      </ToastProvider>
   </Elements>,

   document.getElementById('root')
)
